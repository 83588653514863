section {
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.hero {
  width: 100%;
}

.hero .titles, .hero .banner {
  object-fit: contain;
  max-width: var(--restrict-container);
  width: 100%;
  height: auto;
}

.hero .banner {
  margin: 0 auto;
  padding: 2rem .5rem;
  display: block;
}

.hero .titles {
  margin: 0 auto;
  padding: .5rem .5rem 2rem;
  display: block;
}

@media (width >= 1200px) {
  .hero .titles {
    padding: .5rem .5rem 6rem;
  }
}

.content {
  max-width: var(--restrict-container);
  width: 100%;
  margin: 0 auto;
}

.content .reach-out {
  margin-top: 4rem;
}

.content .reach-out p {
  margin-bottom: 0;
}

.header {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.header h1 {
  text-transform: uppercase;
  text-align: center;
  font-size: 3rem;
}

.header ion-icon {
  color: #ffffff54;
  margin: 1rem;
  font-size: 2rem;
  transition: color .33s cubic-bezier(.77, 0, .175, 1);
}

.header a:hover ion-icon {
  color: #fff;
}

.timer {
  justify-content: center;
  align-items: center;
  gap: 1rem;
  display: flex;
}

@media (width >= 960px) {
  .timer {
    gap: 1.3rem;
  }
}

@media (width >= 1200px) {
  .timer {
    gap: 1.9rem;
  }
}

@media (width >= 1366px) {
  .timer {
    gap: 2.6rem;
  }
}

@media (width >= 1600px) {
  .timer {
    gap: 3rem;
  }
}

.timer .big {
  min-width: 6rem;
  font-size: 4rem;
}

@media (width >= 576px) {
  .timer .big {
    font-size: 5rem;
  }
}

@media (width >= 640px) {
  .timer .big {
    font-size: 8rem;
  }
}

@media (width >= 768px) {
  .timer .big {
    font-size: 10rem;
  }
}

@media (width >= 960px) {
  .timer .big {
    margin-top: -1rem;
    font-size: 11rem;
  }
}

@media (width >= 1200px) {
  .timer .big {
    margin-top: -1rem;
    font-size: 13rem;
  }
}

@media (width >= 1366px) {
  .timer .big {
    margin-top: -3rem;
    font-size: 14rem;
  }
}

@media (width >= 1600px) {
  .timer .big {
    font-size: 15rem;
  }
}

.timer .spacer {
  opacity: 0;
  pointer-events: none;
  display: none;
}

.timer .spacer .big {
  min-width: auto;
  position: relative;
  top: -3rem;
}

.timer .descriptor {
  text-transform: uppercase;
  margin-top: 0;
  font-size: 1.2rem;
  font-weight: bold;
}

@media (width >= 640px) {
  .timer .descriptor {
    margin-top: -1.5rem;
    font-size: 2rem;
  }
}
/*# sourceMappingURL=index.ac1d4e0f.css.map */
