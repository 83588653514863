
section {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.hero {
  width: 100%;
  /* background: linear-gradient(180deg, rgba(0,0,0,0.5) 79%, rgba(0,0,0,1) 100%), url('./img/pexels-karolina-grabowska-6634140.webp') no-repeat; */

  .titles, .banner {
    object-fit: contain;
    max-width: var(--restrict-container);
    width: 100%;
    height: auto;
  }

  .banner {
    padding: 2rem 0.5rem;
    display: block;
    margin: 0 auto;
  }

  .titles{
    padding: 0.5rem 0.5rem 2rem;
    display: block;
    margin: 0 auto;

    @media (min-width: 1200px) {
      padding: 0.5rem 0.5rem 6rem;
    }
  }
}

.content {
  max-width: var(--restrict-container);
  width: 100%;
  margin: 0 auto;

  .reach-out {
    margin-top: 4rem;

    p {
      margin-bottom: 0;
      
    }
  }
}

.header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    text-transform: uppercase;
    font-size: 3rem;
    text-align: center;
  }

  ion-icon {
    font-size: 2rem;
    margin: 1rem;
    color: rgba(255, 255, 255, 0.33);
    transition: color 0.33s cubic-bezier(0.77, 0, 0.175, 1);
  }

  a:hover ion-icon {
    color: rgba(255, 255, 255, 1);
  }
}

.timer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  @media (min-width: 960px) {
    gap: 1.3rem;
  }
  
  @media (min-width: 1200px) {
    gap: 1.9rem;
  }
  
  @media (min-width: 1366px) {
    gap: 2.6rem;
  }
  
  @media (min-width: 1600px) {
    gap: 3rem;
  } 

  .big {
    font-size: 4rem;
    min-width: 6rem;
  
    @media (min-width: 576px) {
      font-size: 5rem;  
    }
    
    @media (min-width: 640px) {
      font-size: 8rem;  
    }
    
    
    @media (min-width: 768px) {
      font-size: 10rem; 
    }
    
    @media (min-width: 960px) {
      font-size: 11rem;
      margin-top: -1rem;  
    }
    
    @media (min-width: 1200px) {
      font-size: 13rem;
      margin-top: -1rem; 
    }
    
    @media (min-width: 1366px) {
      font-size: 14rem;
      margin-top: -3rem;  
    }
    
    @media (min-width: 1600px) {
      font-size: 15rem; 
    } 
  }

  .spacer {
    display: none;
    opacity: 0;
    pointer-events: none;

    .big {
      min-width: auto;
      position: relative;
      top: -3rem;
    }
  }

  .descriptor {
    margin-top: 0rem;
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
  
    @media (min-width: 640px) {
      font-size: 2rem;
      margin-top: -1.5rem;  
    }
  }
}